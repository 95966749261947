/* src/styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 640px) {

    /* Added #root */
    body,
    #root {
        @apply flex flex-col min-h-screen;
    }

    main {
        @apply flex-grow;
    }

    .mobile-footer {
        /* Added mt-auto */
        @apply mt-auto w-full bg-black text-gray-400 py-4 border-t-4 border-yellow-500;
    }

    .mobile-friendly {
        align-items: flex-start;
        padding-top: 1rem;
    }

    .mobile-friendly>div {
        width: 90%;
        max-height: 90vh;
        overflow-y: auto;
        transform: none !important;
    }

    .mobile-friendly h1 {
        font-size: 1.75rem;
    }

    .mobile-friendly p,
    .mobile-friendly label {
        font-size: 0.9rem;
    }

    .mobile-friendly select,
    .mobile-friendly button {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .mobile-dropdown {
        position: static;
    }

    .mobile-dropdown .dropdown-menu {
        position: absolute;
        left: 0;
        top: 100%;
        width: 120px;
    }

    .mobile-dropdown .dropdown-menu button {
        padding: 1rem;
        font-size: 1.1rem;
    }

    .hidden {
        display: none;
    }
}